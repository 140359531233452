<template>
  <div>
    <div class="row align-content-center">
      <div class="col-12">
        <h5 class="pl-1 font-weight-bold my-2">P & I Cases</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-sm table-bordered small">
          <thead>
          <tr>
            <th class="align-middle text-center">P&amp;I Case No.</th>
            <th class="align-middle text-center">Date</th>
            <th class="align-middle text-center">Vessel</th>
            <th class="align-middle text-center">P&amp;I Club</th>
            <th class="align-middle text-center">Diagnosis</th>
            <th class="align-middle text-center">Days</th>
            <th class="align-middle text-center">Result</th>
            <th class="align-middle text-center">Status</th>
            <th class="align-middle text-center">File</th>
            <th class="align-middle text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="crewDocuments">
            <tr v-for="pandi in crewDocuments">
              <td class="align-middle text-center">
                {{ pandi.code ? pandi.code.toUpperCase() : '' }}
              </td>
              <td class="align-middle text-center">{{ pandi.incidentDate }}</td>
              <td class="align-middle text-center">{{ pandi.vessel ? pandi.vessel.toUpperCase() : '' }}</td>
              <td class="align-middle text-center">{{ pandi.club ? pandi.club.toUpperCase() : '' }}</td>
              <td class="align-middle text-center">{{ pandi.diagnosis ? pandi.diagnosis.toUpperCase() : '' }}</td>
              <td class="align-middle text-center">{{ pandi.days }}</td>
              <td class="align-middle text-center">{{ pandi.result  ? pandi.result.toUpperCase() : ''}}</td>
              <td class="align-middle text-center">{{ pandi.active ? 'ACTIVE' : 'INACTIVE'}}</td>
              <td class="align-middle text-center">1</td>
              <td class="align-middle text-center">
                <button type="button" class="btn btn-secondary btn-xs"
                        data-toggle="modal" data-target="#pandi" @click="selectPandI(pandi)">
                  View
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="pandi" tabindex="-1" aria-labelledby="pandi" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content" style="height:auto">
              <div class="modal-header">
                <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW <strong>P&amp;I Report</strong></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-6">
                    <div class="mb-1"><table class="w-100">
                      <tr>
                        <td>P&amp;I Case:</td>
                        <td class="font-weight-bold">{{pandi.code ? pandi.code.toUpperCase():''}}</td>
                        <td>Total Days:</td>
                        <td class="font-weight-bold">{{pandi.days}}</td>
                      </tr>
                      <tr>
                        <td>Vessel:</td>
                        <td class="font-weight-bold">{{pandi.vessel ? pandi.vessel.toUpperCase() : ''}}</td>
                      </tr>
                      <tr>
                        <td>Diagnosis:</td>
                        <td class="font-weight-bold">{{pandi.diagnosis ? pandi.diagnosis.toUpperCase() : ''}}</td>
                      </tr>
                      <tr>
                        <td>Arrival in Manila:</td>
                        <td class="font-weight-bold">{{pandi.arrivalDate}}</td>
                      </tr>
                      <tr>
                        <td>Finished Contract:</td>
                        <td class="font-weight-bold">{{pandi.finish_contract ? 'YES' : 'NO'}}</td>
                      </tr>
                      <tr>
                        <td>Vessel Deviation to Discharge:</td>
                        <td class="font-weight-bold">{{pandi.discharge  === 1 ? 'YES' : pandi.discharge === 2  ?   'NO' : "N/A" }}</td>
                      </tr>
                      <tr>
                        <td>Vessel to Send Replacement:</td>
                        <td class="font-weight-bold">{{pandi.replacement}}</td>
                      </tr>
                      <tr>
                        <td>Plan to Rehire:</td>
                        <td class="font-weight-bold">{{pandi.rehire_plan}}</td>
                      </tr>
                      <tr>
                        <td>P&I Club:</td>
                        <td class="font-weight-bold">{{pandi.club}}</td>
                      </tr>
                      <tr>
                        <td>P&I Club Local Correspondent:</td>
                        <td class="font-weight-bold">{{pandi.local_correspondent ? pandi.local_correspondent.toUpperCase() : ''}}</td>
                      </tr>
                      <tr>
                        <td>Below Deductible:</td>
                        <td class="font-weight-bold">{{pandi.deductible  === 1 ? 'YES' : pandi.deductible === 2  ?   'NO' : "N/A" }}</td>
                      </tr>
                    </table></div>
                    <div>
                      <p class="font-weight-bold" style="font-size: 15px;">P&I Expenses</p>
                      <table class="table table-sm table-bordered small">
                        <thead>
                          <tr>
                            <th class="align-middle text-center">Type</th>
                            <th class="align-middle text-center">Value</th>
                            <th class="align-middle text-center">Currency</th>
                            <th class="align-middle text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="pandi.pandi_expenses">
                            <tr v-for="expense in pandi.pandi_expenses">
                              <td class="text-center font-weight-bold"> {{expense.pandi_expense_type ? expense.pandi_expense_type.toUpperCase():''}}</td>
                              <td class="text-center font-weight-bold">{{formatToMoney(expense.value)}}</td>
                              <td class="text-center font-weight-bold">{{expense.currency ? expense.currency.toUpperCase() : ''}}</td>
                              <td class="text-center font-weight-bold">
                                <button type="button" class="btn btn-xs btn-secondary"
                                        data-target="#pandiExpense"
                                        data-toggle="modal"
                                        @click="getExpenseFileLists(pandi.pandi_expense_attachments,expense.id)"
                                        :disabled="!pandi.pandi_expense_attachments.some(attachment=>attachment.pandi_expense_id === expense.id)">
                                  View Files
                                </button>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                      <table class="table table-sm table-bordered small">
                        <thead>
                        <tr>
                          <th class="align-middle text-center">Currency</th>
                          <th class="align-middle text-center">Total Expenses</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center font-weight-bold">PH Peso (Php) </td>
                            <td class="text-center font-weight-bold">{{formatToMoney(pandi.ph_total_expenses)}}</td>

                          </tr>
                          <tr>
                            <td class="text-center font-weight-bold">US Dollar ($) </td>
                            <td class="text-center font-weight-bold">{{formatToMoney(pandi.us_total_expenses)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="mb-1"><table class="w-100">
                      <tr>
                        <td>Status Remarks:</td>
                        <td class="font-weight-bold">
                          <textarea class="w-100 p-2" rows="5" disabled>{{pandi.status_remarks}}</textarea>
                        </td>
                      </tr>
                      <tr>
                        <td>Disembark Date:</td>
                        <td class="font-weight-bold">{{formatEliteDate(pandi.date_disembark)}}</td>
                      </tr>
                      <tr>
                        <td>Endorsement Date:</td>
                        <td class="font-weight-bold">{{formatEliteDate(pandi.endorsement)}}</td>
                      </tr>
                      <tr>
                        <td>Final Result / Status:</td>
                        <td class="font-weight-bold">{{pandi.result ? pandi.result.toUpperCase() : ''}}</td>
                      </tr>
                      <tr>
                        <td>Case Status:</td>
                        <td class="font-weight-bold">{{pandi.active ? 'ACTIVE' : 'CLOSED'}}</td>
                      </tr>
                    </table>
                    </div>
                  </div>
                  <div class="col-6">
                    <h5 class="font-weight-bold my-2">P & I Checkup Records</h5>
                    <template v-if="pandi.pandiCheckups">
                      <div class="card p-2" v-for="(checkups,index) in pandi.pandiCheckups">
                        <div class="card-title">
                          <p class="font-weight-bold mb-0">Check Up Report # {{ index +1 }}:</p>
                        </div>
                        <div class="card-body m-0 p-0">
                          <div class="row mb-0">
                            <div class="col-12">
                              <table class="w-100">
                                <tr>
                                  <td>Date :</td>
                                  <td>{{formatEliteDate(checkups.checkup_date)}}</td>
                                  <td style="width: 20%;">Attachment</td>
                                  <td>
                                    <button role="button" class="btn-xs btn btn-secondary" :disabled="!checkups.hasFile"
                                            @click="getPandiCheckUpAttachment(checkups.id)">View</button>
                                  </td>
                                </tr>
                                <tr style="margin-bottom: 10px">
                                  <td>Remarks:</td>
                                  <td>
                                    <textarea class="w-100 p-2" rows="5" disabled>{{checkups.remarks ? checkups.remarks.toUpperCase() : null}}</textarea>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" >Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" data-backdrop="static" data-keyboard="false" id="pandiExpense" aria-labelledby="pandi" aria-hidden="true">
          <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content" style="height:auto">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold" id="crewIncidentBehaviorTitle">P&I Expense Attachments</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <table class="table table-sm table-bordered small">
                  <thead>
                    <tr>
                      <td class="align-middle text-center font-weight-bold">Attachment Name</td>
                      <td class="align-middle text-center font-weight-bold">Action</td>
                    </tr>
                  </thead>
                  <tbody>
                      <template v-if="expenseAttachments">
                        <tr v-for="attachment in expenseAttachments">
                          <td class="align-middle text-center font-weight-bold">{{attachment.name}}</td>
                          <td class="align-middle text-center">
                            <button type="button" class="btn btn-secondary btn-xs" @click="viewExpenseAttachment(attachment.id)">View File</button>
                          </td>
                        </tr>
                      </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "pandi",
  data(){
    return {
      pandi:{} ,
      expenseAttachments:{},
    }
  },
  async created() {
    const params = {
      cms_id: this.$route.params.id
    }
    await this.getPandIs(params);
  },
  methods: {
    ...mapActions(['getPandIs']),
    formatEliteDate(date) {
      return DateService.dateEliteFormat(date)
    },
    selectPandI(pandi){
      this.pandi=Object.assign({},pandi);
    },
    formatToMoney(amount) {
      return Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    async getPandiCheckUpAttachment(checkupId){
      const params = {
        pandiCheckupFile:checkupId
      }
      const file=await CrewService.getPandICheckupAttachmentFromCms(params)
      const commentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(commentFile){
        swal.fire({
          title: `<strong>P&I Checkup Report</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${commentFile}"></iframe>`
        })
      }
    },
    getExpenseFileLists(attachments,expense_id){
      this.expenseAttachments=attachments.filter(expense=>expense.pandi_expense_id===expense_id);
    },
    async viewExpenseAttachment(expenseId){
      const params = {
        pandiExpenseAttachment:expenseId
      }
      const file=await CrewService.getPandIExpenseAttachmentFromCms (params)
      const expenseFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(expenseFile){
        swal.fire({
          title: `<strong>P&I Expense Attachment</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${expenseFile}"></iframe>`
        })
      }
    },

  },
  computed: {
    ...mapGetters(['crewDocuments'])
  }
}
</script>

<style scoped>
.modal { overflow: auto !important; }
</style>
